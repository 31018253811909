<template>
<el-container>
  <div class="container">
    <el-header><Header /></el-header>
      <el-main class="">
        <router-view></router-view>
      </el-main>
    <Footer />
  </div>
</el-container>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { RouterView } from 'vue-router'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    RouterView
  }
}
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.el-main{
  height:100%;
}
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  text-align: center;
  width:100%;
}
.logo {
  width: 50%;
}
</style>
