<template>
  <el-form :model="form" label-width="120px">
    <el-form-item label="生成个数：">
      <el-input-number v-model="form.count" />
    </el-form-item>
    <el-form-item label="大小写：">
      <el-radio-group v-model="form.isUpper">
        <el-radio label="大写" />
        <el-radio label="小写" />
      </el-radio-group>
    </el-form-item>
    <el-form-item label="分隔符：">
      <el-radio-group v-model="form.withDash">
        <el-radio label="带分隔符" />
        <el-radio label="不带分隔符" />
      </el-radio-group>
    </el-form-item>
    <el-form-item label="前后括号：">
      <el-radio-group v-model="form.withBrackets">
        <el-radio label="带括号" />
        <el-radio label="不带括号" />
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">生成</el-button>
      <el-button type="primary" plain @click="onCopy">复制</el-button>
    </el-form-item>
    <el-form-item label="生成结果">
      <el-input v-model="form.content" type="textarea" rows="20"/>
    </el-form-item>
  </el-form>
</template>

<script>
import { reactive } from 'vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'GuidPage',
  props: {},
  setup() {
    const form = reactive({
      count: 5,
      withDash: '带分隔符',
      isUpper: '大写',
      withBrackets: '带括号'
    })
    const onSubmit = () => {
      form.content = ''
      for(let idx = 0; idx < form.count; idx++){
        let uuidValue = "", k, randomValue;  
        for (k = 0; k < 32; k++) {  
          randomValue = Math.random() * 16 | 0;  
        
          if ((k == 8 || k == 12 || k == 16 || k == 20) && form.withDash === '带分隔符') {  
            uuidValue += "-"  
          }  
          uuidValue += (k == 12 ? 4 : (k == 16 ? (randomValue & 3 | 8) : randomValue)).toString(16); 
          if(form.isUpper == '大写') {
            uuidValue = uuidValue.toUpperCase()
          } else {
            uuidValue = uuidValue.toLowerCase()
          }
        }
        if(form.withBrackets == '带括号') {
          form.content += `{${uuidValue}}\n`;  
        } else {
          form.content += `${uuidValue}\n`;  
        }
      }
      localStorage.setItem('latest-setting', JSON.stringify({...form, content: ''}))
    }
    const onCopy = () => {
        navigator.clipboard.writeText(form.content);
        ElMessage('已复制')
    }
    return {
      form,
      onSubmit,
      onCopy
    }
  },
   beforeCreate(){
    let obj = localStorage.getItem("latest-setting")
    if (obj) {
      let setting = JSON.parse(obj)
      console.log(setting)
      this.form.count = setting.count
      this.form.withDash = setting.withDash
      this.form.withBrackets = setting.withBrackets
      this.form.isUpper = setting.isUpper
    }
  }
}
</script>

<style scoped>
.title{
  font-size: 30px;
}
</style>
