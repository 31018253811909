<template>
  Files page
</template>

<script>

export default {
  name: 'ToolsFilesPage',
  props: {},
  setup() {
    return {
    }
  }
}
</script>

<style scoped>

</style>
