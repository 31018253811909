<template>
  <text>关于网站 - 建设中</text>
</template>

<script>

export default {
  name: 'AboutPage',
  props: {},
  setup() {
    return {
    }
  }
}
</script>

<style scoped>

</style>
