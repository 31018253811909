<template>
<el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal" :ellipsis="false" @select="handleSelect"
 >
    <el-menu-item index="/index">
      <img style="width: 60px" src="@/assets/logo.png" alt="橙子英语绘本"/>
      <span class="title">橙子英语绘本</span>
    </el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="/">首页</el-menu-item>
    <el-sub-menu index="">
      <template #title><text class="menu-title">常用工具</text></template>
      <el-menu-item index="/guid" router="/tools">GUID生成器</el-menu-item>
      <el-menu-item index="/json">JSON生成器</el-menu-item>
      <el-menu-item index="/files">文件重命名助手</el-menu-item>
      <el-menu-item index="/latex">数学公式编写助手</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="/resources">资源分享</el-menu-item>
    <el-menu-item index="/about">
      <router-link to="/about">关于网站</router-link>

    </el-menu-item>
  </el-menu>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'Header',
  props: {},
  setup() {
    const router = useRouter()
    const activeIndex = ref('1')
    const handleSelect = (key, keyPath) => {
      console.log(`key=${key},keyPath=${keyPath}`)
      router.push(key)
    }
    return {
      activeIndex,
      handleSelect,
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-grow {
  flex-grow: 1;
}
.menu-title {
  font-size: 16px;
};
.logo {
  height: 38px;
  width: 38px;
  vertical-align: middle;
}
.title {
  color: #c40000;
  font-size:26px;
  vertical-align: middle;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  
}
.el-menu-item {
  font-size: 16px;
}
a {
  color: #42b983;
}
</style>
