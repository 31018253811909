<template>
  <text class="not-found">404</text>
</template>

<script>

export default {
  name: 'NotFoundPage',
  props: {},
  setup() {
    return {
    }
  }
}
</script>

<style scoped>
.not-found {
  font-size: 40px;
}
</style>
