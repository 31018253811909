<template>
  <div class="footer">
    <div class="footer-line">
      <span><a href="https://beian.miit.gov.cn">陕ICP备2021015379号-1</a></span>
    </div>
    <div class="footer-line">
      <span>©2023 橙子英语绘本 版权所有</span>
    </div>
    <div class="footer-line">
      <span class="updated-time">最近更新：{{now}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
  setup() {
    return {
       now: '2024年1月13日'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .el-row {
  margin-top: 10px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
} */

.footer {
  background-color: #282828;
  font-size: 14px;
  color:#d5d5d5;
  text-align: center;
  width:100%;
  position: absolute;
  bottom: 5px;
}
.footer-line {
  height: 24px;
}
span,a {
  color: white;
}
.updated-time {
  float: right;
  font-size: 12px;
  color: gray;
  padding-right:5px;
}
</style>
