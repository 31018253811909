<template>
  资源分享 - 建设中
</template>

<script>

export default {
  name: 'ResourcesPage',
  props: {},
  setup() {
    return {
    }
  }
}
</script>

<style scoped>

</style>
