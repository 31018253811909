import * as VueRouter from 'vue-router';

import HomePage from '../pages/HomePage.vue'
import ResourcesPage from '../pages/ResourcesPage.vue'
import AboutPage from '../pages/AboutPage.vue'

//Tools
import GuidPage from '../pages/tools/GuidPage.vue'
import JsonPage from '../pages/tools/JsonPage.vue'
import FilesPage from '../pages/tools/FilesPage.vue'
import LatextPage from '../pages/tools/LatexPage.vue'
import NotFoundPage from '../pages/NotFoundPage.vue'
const routes = [
    
    {
        path: '/',
        redirect: '/index',
    },
    {
        path: '/index',
        name: 'index',
        component: HomePage
    },
    {
        path: '/about',
        name: 'about',
        component: AboutPage
    },
    {
        path: '/guid',
        name: 'guid',
        component: GuidPage
    },
    {
        path: '/files',
        name: 'files',
        component: FilesPage
    },
    {
        path: '/json',
        name: 'json',
        component: JsonPage
    },
    {
        path: '/latex',
        name: 'latex',
        component: LatextPage
    },
    {
        path: '/resources',
        name: 'resources',
        component: ResourcesPage
    },
    {
        path: '/404',
        name: '404',
        component: NotFoundPage
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404'
    }
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    // console.log(`from ${from.fullPath}`)
    // console.log(`to ${to.fullPath}`)
    // let isAuthenticated = true;
    // if (!isAuthenticated && to.name !== 'Login') {
    //     next({ name: 'Login' })
    // }
    // else next()
    next()
  })
export { router };
