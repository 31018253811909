<template>
  LaTeX page
</template>

<script>

export default {
  name: 'ToolsJsonPage',
  props: {},
  setup() {
    return {
    }
  }
}
</script>

<style scoped>

</style>
