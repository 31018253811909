<template>
  <div class="block text-center" m="t-4">
    <el-carousel trigger="click" height="600px" :interval="4000">
      <el-carousel-item v-for="item in miniprograms" :key="item">
        <el-row>
          <el-col :span="24">
            <h3 class="app-title justify-center">{{ item.name }}</h3>    
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
        <el-image style="width: 200px; height: 200px" :src="require(`../assets/${item.icon}`)" fit="contain" />
        </el-col>
        </el-row>
        <el-row class="tags">
          <el-col :span="24">
        <el-tag class="tag" v-for="t in item.tags" :key="t" >{{t}}</el-tag>
        </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <text class="app-desc">{{ item.description }}</text>
            </el-col>
        </el-row>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "MainHome",
  props: {},
  computed: {
  },
  setup() {
    return {
      miniprograms: [
        {
          name: "天天坚持进步",
          icon: "app-raz.jpg",
          description: "学习RAZ分级的小程序，从AA到Z2共29个级别,2000多本绘本。还有磨耳朵，记忆曲线复习，单词学习，绘本测试等功能。",
          tags: ["图文", "磨耳朵","绘本测试","单词学习"]
        },
        {
          name: "橙子英语绘本",
          icon: "app-books.jpg",
          description: "牛津树，大猫，海尼曼等等英语分级的图文阅读，以及桥梁书，章节书。",
          tags: ["图文","磨耳朵","记忆曲线"]
        },
        {
          name: "橙子英语听力",
          icon: "app-audios.jpg",
          description: "各种英语音频，桥梁书，章节书音频",
          tags: ["音频","英语读物", "桥梁书","章节书","传统名著"]
        },
        {
          name: "橙子自然拼读",
          icon: "app-phonics.jpg",
          description: "英语自然拼读学习资源",
          tags: ["自然拼读"]
        },
        {
          name: "作文之美",
          icon: "app-chinese.jpg",
          description: "搜罗天下优秀作文",
          tags: ["优秀范文"]
        }
      ]
    };
  }
};
</script>

<style scoped>
.time {
  font-size: 12px;
  color: #999;
}
.app-title {
  font-size: 26px;
}
.app-desc {
  font-size: 15px;
  color:#8CBDB9;
  
}
.tags{
  padding:10px 0;
}
.tag {
  padding-right:8px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 80%;
  display: block;
}
.el-carousel__item h3 {
  color:#2D3E4E;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color:#E8ECEB;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #E8ECEB;
}
</style>
