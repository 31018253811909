<template>
  <el-form :model="form" label-width="120px">
    <el-form-item label="重复次数：">
      <el-input-number v-model="form.repeatTimes" />
    </el-form-item>
    <el-form-item label="开始计数：">
      <el-input-number v-model="form.startValue" />
    </el-form-item>
    <el-form-item label="是否定长：">
      <el-radio-group v-model="form.isFixLength">
        <el-radio label="定长" />
        <el-radio label="不定长" />
      </el-radio-group>
    </el-form-item>
    <el-form-item label="定长长度：">
      <el-input-number v-model="form.fixLength" />
    </el-form-item>
    <el-form-item label="模板：">
      <el-input v-model="form.formatString" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">生成</el-button>
      <el-button type="primary" plain @click="onCopy">复制</el-button>
    </el-form-item>
    <el-form-item label="生成结果">
      <el-input v-model="form.content" type="textarea" rows="20"/>
    </el-form-item>
  </el-form>
</template>

<script>
import { reactive } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  name: 'FilesPage',
  props: {},
  setup() {
    const form = reactive({
      repeatTimes: 10,
      startValue: 0,
      isFixLength: '定长',
      fixLength: 2,
      formatString: "{'name{idx}': 'value{idx}'}",
      content: '',
    })
    const onSubmit = () => {
      form.content = ''
      for(let idx = form.startValue; idx < form.repeatTimes; idx++) {
        let idxPart = '';
        if(form.isFixLength == '定长') {
          idxPart = idx.toString().padStart(form.fixLength, '0')
        } else {
          idxPart = idx.toString()
        }
        let result = form.formatString.replace(/{idx}/g, idxPart)
        form.content += `${result}\n`; 
      }
      localStorage.setItem('latest-setting-json', JSON.stringify({...form, content: ''}))
    }
    const onCopy = () => {
        navigator.clipboard.writeText(form.content);
        ElMessage('已复制')
    }
    return {
      form,
      onSubmit,
      onCopy
    }
  },
  beforeCreate(){
    let obj = localStorage.getItem("latest-setting-json")
    if (obj) {
      let setting = JSON.parse(obj)
      console.log(setting)
      this.form.repeatTimes = setting.repeatTimes
      this.form.startValue = setting.startValue
      this.form.isFixLength = setting.isFixLength
      this.form.fixLength = setting.fixLength
      this.form.formatString = setting.formatString
    }
  }
}
</script>

<style scoped>
.title{
  font-size: 30px;
}
</style>
